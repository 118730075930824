export const GENERIC_ERROR_TITLE = "There was an error.";
export const INVALID_PASSENGER_TITLE = "Invalid Passengers";
export const TOO_MANY_PASSENGERS_TITLE = "Too Many Passengers";

export const GENERIC_ERROR_SUBTITLE =
  "There was an error, please try again or reload the page.";
export const MIN_AGE_NOT_MET_SUBTITLE =
  "Minors must be accompanied by at least one adult";
export const NO_PASSENGERS_SUBTITLE = "No passengers have been selected";
export const SEATED_INFANTS_UNSUPPORTED_SUBTITLE =
  "Seated infants are not supported on this flight. Please go back and select a lap infant to continue with the purchase.";
export const TOO_MANY_LAP_INFANTS_SUBTITLE =
  "There are too many lap infants. Please make sure you have an adult passenger for every lap infant.";
// TODO: figure out if this error fires and if max PAX is correct
export const TOO_MANY_PASSENGERS_SUBTITLE =
  "Oops! The airline only allows you to book 6 passengers in a single reservation. Please go back and make separate reservations if you have more than 6 passengers.";
export const LAP_INFANT_TOO_OLD_SUBTITLE =
  "This passenger is too old to sit on a lap. Please go back and select a seated infant or child.";

export const SELECTED_TRAVELERS_EXCEED_ROOM_CAPACITY_TITLE = (
  maxGuests: number
) => `The room you've chosen allows for a maximum of (${maxGuests}) guests.`;
export const SELECTED_TRAVELERS_EXCEED_ROOM_CAPACITY_SUBTITLE =
  "To continue booking this package, update your traveler selection so they do not exceed the maximum room occupancy, or select a different room. If you select a different room, you will be required to reselect your flights.";

export const SEARCHED_TRAVELER_COUNT_NOT_MET_TITLE =
  "It looks like you've selected fewer travelers than you originally searched for.";
export const SEARCHED_TRAVELER_COUNT_NOT_MET_SUBTITLE =
  "If your trip includes fewer travelers than you originally searched for, please continue. If not, update your travelers to match what you originally searched for.";

export const UPDATE_TRAVELERS_CTA_TEXT = "Update Travelers";
export const CONTINUE_CTA_TEXT = "Continue";
export const SELECT_DIFFERENT_ROOM_CTA_TEXT = "Select a different room";
